.container {
  width: 100%;
  padding-bottom: 6em;
  background: #151616;
  color: white;
}

.banner {
  width: 100%;
  height: 22em;
  margin-bottom: 0.5em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
}

.bannerContent {
  width: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.bannerContent .musiswapIcon {
  width: calc(10em + 10vmax);
}

.bannerContent .header {
  width: 100%;
  text-align: center;
  color: white;
  font-size: calc(1.5rem + 6vmax);
  font-weight: bold;
}

.colour {
  background: linear-gradient(#00CC9B 20%, #151616 80%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.nftReleases {
  width: 100%;
  max-width: 64em;
  margin: 0 auto;
  border-radius: 0.5em;
  margin-bottom: 1em;
}

.nftReleases .header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.nftReleases .header .backheader {
  width: 100%;
  text-align: center;
  color: white;
  font-size: calc(1.5rem + 4vmax);
  font-weight: bold;
  opacity: 0.1;
}

.nftReleases .header .frontheader {
  color: white;
  font-size: calc(1rem + 1.8vmax);
  position: absolute;
  text-align: center;
}

.featuredNft {
  width: 100%;
  max-width: 86em;
  margin: 0 auto;
  padding: 0 0.5em;
}

.featuredTitle {
  font-size: 2rem;
  margin-bottom: 1em;
  font-weight: bold;
}

.nftWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2em 3em;
}


@media screen and (max-width: 1200px) {
  .featuredNft {
    max-width: 64em;
  }

  .nftWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px) {

  .featuredNft {
    max-width: 42em;
  }

  .nftWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 680px) {

  .featuredNft {
    max-width: 40em;
    padding: 0 0.5em;
  }

  .nftWrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 1em;
    justify-items: center;
  }
}

@media screen and (max-width: 480px) {
  .featuredNft {
    max-width: 18em;
  }
}