
:root {
--main-bg: #0A0A0A;
--main-accent: #00CC9B;
--main-text: #ffffff;
--card-bg: #27292C;
}

@import url('https://fonts.googleapis.com/css?family=Poppins');
@import 'react-slidy/lib/styles.css';
html {
    font-family: 'Poppins', sans-serif;
}

*{
    border: 1px solid white;
}
body {
    color:#fff;
    /* font-size: medium; */
}
@import '~antd/dist/antd.css';

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #27292C; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #00CC9B; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #018D6C; 
  }

.fullFrame {
    width: 100vw;
}
.almostFullFrame {
    width: 95vw;
    margin-bottom: 1em;
}

.bounceImage {
    animation: bounce 3s alternate;
    -webkit-animation: bounce 5s alternate;
}

.almostFullFrame.bounceImage {
    animation: bounce 3s infinite;
    -webkit-animation: bounce 5s infinite;
}

.bannerImg {
    width: 100vw;
}
.bannerTitle {
    width: 100vw;
    align-items: baseline;

}

.sidebar{
    background-color: #27292C;
}

body{
    color: #fff !important;
}

.bannerHero {
    width: 100vw;
    height: 600px;
    color: #fff;
    background-size: cover;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9) ), url('/assets/images/BillieHero.png'); */
    position: relative;
}
.bannerHero h1 {
    font-size: 30px;
    /* text-transform: uppercase; */
    /* position: absolute; */
    bottom: 1%;
    width: 100%;
    text-align: left;
}

.bannerHero h2 {
    font-size: 22px;
    /* color: #fff; */
    /* text-transform: uppercase; */
    /* font-family: poppins; */
    position: absolute;
    bottom: 1%;
    width: 100%;
    text-align: left;
}
.landingTextCard {
    border-color: #fff;
    border-width: 0.2em;
    align-content: left;
    justify-content: left;
    align-content: flex-start;
    background-color: #27292C;
    margin: auto;
}
.launchButton {
    background-color: #00CC9B;
    font-weight: bold;
    font-size: large;
    border-radius: 1em;
    /* padding: 0.5em; */
    margin: 1em;
    margin-bottom:2em;
    color: white;
    text-decoration: none;
    justify-content: left;
    align-content: left;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: .75em;
    padding-bottom: .75em;




}
.launchButton:hover {
    background-color: #018D6C;



}
.launchLink {
    text-decoration: none;
    color: white;
}
.swapDiv {
    /* padding-top: 1em; */
    margin-top: 1em;

}
/* Collectibles page or mrch */
.collectiblesFrame {
    margin-top: 1em;
}
.collectiblesFrame:hover {
    opacity: 90%;
}


/* FOOTER */
.socialIconsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* margin: 1em; */
    /* padding: 1em; */
}
.footerBar {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 100vw;
}
.footerBar a:hover {
    opacity: 50%;
}

.main{
    width: 106% !important;
}

.carousel{
    overflow: auto;
}
.scroll-bar {
    overflow-x: scroll !important;
  }


  /* NFT marketplace */
  .nftCard {
      background-color: #000;
  }

  .nftCard:hover {
    background-color: #27292C;
    /* cursor: pointer; */
}
.openSeaBuy {
    background-color: #00CC9B;
}
button {
    background-color: #00CC9B;
}

/* animations */
@-webkit-keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
    40% {-webkit-transform: translateY(-1em);} 
    60% {-webkit-transform: translateY(-2emx);} 
 } 
 
 @keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-1em);} 
    60% {transform: translateY(-2em);} 
 }
 
 .bounce { 
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
 }
 /* .bounce:hover {
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
 } */

