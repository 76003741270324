.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container * {
  color: white;
}

.player {
  width: 100%;
  max-width: 20em;
  height: 20em;
  margin-bottom: 1em;
}

.player img {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.control {
  width: 100%;
  max-width: 28em;
  margin-bottom: 2em;
}

.range {
  width: 100%;
  position: relative;
}

.range input[type="range"] {
  width: 100%;
}

.duration {
  font-style: italic;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, -100%);
}

.description {
  width: 100%;
}

.description h1 {
  font-size: 1.5rem;
  text-align: center; 
}

.description p {
  font-size: 1.1rem;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.playBtn img {
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;
}

.verified {
  margin-left: 0.5em;
  width: 1.2em;
  height: 1.2em;
}

.upload {
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin-top: 2em;
}

.upload .btn {
  padding: 0.75em 4em;
  font-weight: bold;
  text-transform: uppercase;
  background: #00CC9B;
  color: white;
  box-shadow: 0px 1.2541px 1.2541px rgba(0, 0, 0, 0.25);
  border-radius: 3.76231px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.3s;
}

.upload .btn:hover {
  background: #01ad85;
}

@media screen and (max-width: 640px) {
  .player {
    max-width: 16em;
    height: 16em;
  }

  .description h1 {
    font-size: calc(1rem);
  }

  .description p {
    font-size: calc(1rem);
  }
}
