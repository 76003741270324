.container {
  width: auto;
  max-width: 20em;
  border: 1px solid #404242;
  padding: 1em 2em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  background: #27292C;
}

.name {
  font-size: 2rem;
  margin-bottom: 0.5em;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  width: 7em;
  overflow: scroll;
}

.image {
  width: 100%;
  height: 13em;
  margin-bottom: 0.5em;
}

.priceTitle {
  width: 100%;
  text-align: left;
  font-size: 1rem;
}

.price {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  margin-bottom: 0.5em;
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  cursor: pointer;
}

.buttons .buy:hover {
  opacity: 0.8;
}

.buttons .offer:hover {
  opacity: 0.8;
}

.buttons .buy, .buttons .offer {
  padding: 0.25em 1em;
  font-size: 14px;
  border-radius: 5px;
  color: white;
  transition: all 230ms;
}

.buttons .buy {
  background: #00CC9B;
}

.buttons .offer {
  background: #BEBEBE;
}

.coin {
  display: block;
  width: 2em;
}

.noSales {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  margin: 0.5em 0;
  color: rgba(255, 255, 255, 0.6);
}

@media screen and (max-width: 680px) {
  .coin {
    width: 1.2em;
  }
}
