.display {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #404242;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3em 0;
  position: relative;
}

.noImage {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 7em);
}

.images {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  width: 16em;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
}

.imageWrapper._2 {
  margin-left: -2em;
}
.imageWrapper._0 {
  margin-right: -2em;
}

.imageContainer {
  border: 2px solid #404242;
  background: #27292C;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
}

.imageWrapper._0 > .imageContainer,
.imageWrapper._2 > .imageContainer {
  width: 100%;
  height: 100%;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

.imageWrapper._2 > .imageContainer {
  transform-origin: right;
  transform: rotateY(-30deg);
}

.imageWrapper._0 > .imageContainer {
  transform-origin: left;
  transform: rotateY(30deg);
}

.imageWrapper._1 {
  z-index: 10;
  width: 18em;
}

.imageWrapper._1 > .imageContainer {
  width: 100%;
  height: 100%;
}

.imageWrapper.hide{
  visibility: hidden;
}

.imageWrapper.show {
  visibility: none;
}

.imageWrapper._1.animateLeft {
  animation: slide-left 650ms forwards;
}
.imageWrapper._1.animateRight {
  animation: slide-right 650ms forwards;
}

.imageWrapper._0.animateLeft {
  animation: zoom-in-left 950ms forwards;
}
.imageWrapper._0.animateRight {
  animation: zoom-out-right 950ms forwards;
}

.imageWrapper._2.animateLeft {
  animation: zoom-out-left 950ms forwards;
}
.imageWrapper._2.animateRight {
  animation: zoom-in-right 950ms forwards;
}

.arrows {
  position: absolute;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
}

.arrowRight,
.arrowLeft {
  cursor: pointer;
  background: transparent;
  transform: scale(3);
  color: var(--text-color);
}

.arrowLeft img,
.arrowRight img {
  display: block;
  width: 1em;
}

.inactive img{
  filter: opacity(10%);
}

.image {
  width: 100%;
  height: auto;
  margin-bottom: 0.5em;
}

.name {
  font-size: 2rem;
  margin-bottom: 0.5em;
  text-align: center;
}


@keyframes slide-left {
  0% {
    opacity: 0.4;
    transform: translateX(-6em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    opacity: 0.4;
    transform: translateX(6em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes zoom-out-right {
  10% {
    opacity: 0;
    transform: scale(1) translateX(8em);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(0);
  }
}

@keyframes zoom-out-left {
  0% {
    opacity: 0;
    transform: translateX(-8em);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(0);
  }
}

@keyframes zoom-in-right {
  0% {
    opacity: 0;
    transform: translate(-8em, 2em);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }
}

@keyframes zoom-in-left {
  0% {
    opacity: 0;
    transform: translate(8em, 2em);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }
}

@media (max-width: 1200px) {
  .display {
    width: 90%;
  }
}

@media (max-width: 1000px) {

  .imageWrapper._0,
  .imageWrapper._2 {
    display: none;
  }
}

@media (max-width: 900px) {
  .select {
    width: 100%;
    position: static;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 25;
    margin-bottom: 1em;
  }

}

@media (max-width: 768px) {

  .imageWrapper._1 {
    height: auto;
  }
}

@media (max-width: 480px) {
  .display {
    padding: 1em 0;
  }
  .arrows {
    width: 95%;
  }

  .noImage {
    font-size: 1rem;
  }

  .imageWrapper._1 {
    width: 90%;
  }
}