.bgContainer {
    width: 100%;
    overflow: hidden;
    height: 46em;
    position: relative;
}

.bgWav {
    display: block;
    width: 100%;
}

.bgOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(21, 22, 22, 0.75);
}

.bgContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0 0.5em;
}

.logo {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 4em;
    margin-top: 10em;
    transition: all 250ms;
}

.image {
    width: 100%;
    animation: pulse 1s infinite alternate;
}

.description {
    font-size: calc(1rem + 0.5vmax);
    font-weight: 500;
    margin-right: 2em;
    color: transparent;
    background:#00CC9B;
    -webkit-background-clip: text;
    background-clip: text;
}

.text {
    width: 80%;
    font-size: calc(1rem + 0.5vmax);
    max-width: 40em;
    margin-bottom: 2em;
    transition: all 250ms;
}

.melody1,
.melody2 {
    display: block;
    position: absolute;
    background: transparent;
}

.parallax {
    width: 20%;
    top: 0;
    left: 0;
    position: absolute;
}

.parallax .melody1:first-child {
    top: 0;
    left: 0;
    transform: translateX(0) translateY(0) rotate(180deg);
}

.parallax .melody1:last-child {
    top: -6em;
    left: -8em;
}

.melody1 {
    display: absolute;
    width: 100%;
}

.melody2 {
    top: 20%;
    right: 0;
    width: 10%;
    animation: beat 15s infinite alternate;
}

.imageContainer {
    display: flex;
}

@keyframes beat {
    0% {transform: translate(10px, 10px) rotate(10deg);}
    40%, 60% {transform: translate(0px, 0px) rotate(0deg);}
    62.5% {transform: translate(0px, 0px) rotate(-12deg);}
    63% {transform: translate(0px, 0px) rotate(4deg);}    
    63.5% {transform: translate(0px, 0px) rotate(-10deg);}
    64% {transform: translate(0px, 0px) rotate(8deg);}
    64.5%, 74.5% {transform: translate(0px, 0px) rotate(-4deg);}    
    100% {transform: translate(10px, 10px) rotate(5deg);}
}

@keyframes pulse {
    0%{transform: scale(0.98);}
    100%{transform: scale(1);}
}

.explorationWrapper {
    border: 1px solid red;
    width: 100%;
    padding: 2em;
}

@media screen and (max-width: 1000px) {
    .bgContainer {
        height: 40em;
    }

    .parallax .melody1:last-child {
        top: -3em;
        left: -4em;
    }
}

@media screen and (max-width: 768px) {
    .bgContainer {
        height: 36em;
    }

    .logo {
        margin-top: 6em;
        width: 50%;
    }
}

@media screen and (max-width: 560px){
    .parallax .melody1:last-child {
        top: -1.5em;
        left: -2.5em;
    }

    .melody1 {
        width: 80%;
    }

    .logo {
        width: 80%;
    }

    .text {
        width: 100%;
        font-size: calc(1rem + 0.25vmax);
        text-align: center;
    }
}

@media screen and (max-width: 480px){
    .bgContainer {
        height: 28em;
    }
    .logo {
        margin-top: 4em;
        margin-bottom: 2em;
        width: 80%;
    }

    .text {
        margin-bottom: 1em;
    }

    .description,
    .text {
        font-size: 0.9rem;
    }

    .parallax .melody1:last-child {
        top: -1.5em;
        left: -2em;
    }
}

