.buy:hover {
  opacity: 0.8;
}

.buy {
  margin-top: 2em;
  padding: 0.25em 1em;
  font-size: 14px;
  border-radius: 5px;
  color: white;
  transition: all 230ms;
  background: #00CC9B;
  cursor: pointer;
}

.dropsTitle {
  font-weight: 500;
  margin-bottom: 0.5em;
  width: 100%;
  text-align: center;
}

.dropsDate {
  font-size: 1.4rem;
  font-weight: bold;
  opacity: 0.7;
}