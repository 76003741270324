.container {
  padding: 0.5em 1em;
  border-radius: 0.1em;
  cursor: pointer;
  background: #00CC9B;
  font-weight: bold;
  color: white;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  transition: all 250ms;
}

.container:hover {
  background: #00b389;
}

@keyframes rotate {
  from{transform: rotate(0);}
  to{transform: rotate(360deg);}
}