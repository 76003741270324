.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2em;
}

.formInputWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.dateWrapper label,
.formInputWrapper label {
  flex: 15%;
  font-size: 1rem;
  text-align: right;
  white-space: nowrap;
}

.dateWrapper div,
.formInputWrapper input {
  flex: 85%;
  background: transparent;
  color: white;
  padding: 0.25em;
  font-size: 1rem;
  outline: none;
  width: 100%;
  border: 1px solid gray;
  outline: none;
}

.formInputWrapper input::placeholder {
  font-size: 0.75rem;
}

.dateWrapper {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2em;
  gap: 1em;
}

.dateWrapper.show {
  display: flex;
}

.submitBtn {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: none;
}

.submitBtn.show {
  display: flex;
}

.submitBtn .submit {
  padding: 0.75em 4em;
  font-weight: bold;
  text-transform: uppercase;
  background: #00CC9B;
  color: white;
  box-shadow: 0px 1.2541px 1.2541px rgba(0, 0, 0, 0.25);
  border-radius: 3.76231px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.3s;
}

.submitBtn .submit:hover {
  background: #01ad85;
}

.musicList {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
}

.song {
  width: 100%;
}

.editBtn {
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
  display: none;
}

.editBtn.show {
  display: flex;
}

.editBtn .delete,
.editBtn .save {
  padding: 0.5em 2em;
  background: #00CC9B;
  color: white;
  box-shadow: 0px 1.2541px 1.2541px rgba(0, 0, 0, 0.25);
  border-radius: 3.76231px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 300ms;
  font-weight: bold;
}

.editBtn .delete:hover,
.editBtn .save:hover {
  background: #01ad85;
}

@media screen and (max-width: 640px) {
  .dateWrapper,
  .musicList,
  .editBtn,
  .formInputWrapper {
    gap: 0.5em;
  }
  .form {
    gap: 1em;
  }
}