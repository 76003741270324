@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Medium.ttf') format('ttf'),
         url('Poppins-Medium.ttf') format('ttf');

}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Black.ttf') format('ttf'),
         url('Poppins-Black.ttf') format('ttf');

}