.container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 16em;
    color: white;
}

.container li {
    list-style-type: none;
}

.heading {
    width: 100%;
    margin-bottom: 4em;
}

.heading h1 {
    font-size: 8rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    line-height: 85.5%;
    font-size: 8rem;
    margin-bottom: 0.1em;
    color: white;
}

.heading h1 .accent {
    margin: 0;
    padding: 0;
    display: inline-block;
    color: transparent;
    background: linear-gradient(180deg, #00CC9B 30%, #00523e1a 90%);   
    -webkit-background-clip: text;
    background-clip: text;
}

.heading p {
    font-size: 1.5rem;
    text-align: center;
}

.searchAndSort {
    width: 100%;
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
    z-index: 5;
}

.playlist {
    width: 100%;
    max-width: 56em;
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
  }

.musicList {
    width: 100%;
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.playBtnWrapper {
    position: absolute;
    bottom: 8em;
    right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    padding: 1em;
    background: black;
    box-shadow: 0px 17.6243px 17.6243px rgba(0, 0, 0, 0.25);
}

.playerWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4em;
}

.loadBtn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadBtn .btn {
    cursor: pointer;
    text-transform: capitalize;
    font-style: italic;
    border-bottom: 1px solid white;
    font-size: 1.2rem;
}

.fallback {
    width: 100%;
    border: 2px solid #00CC9B;
    padding: 1em;
    font-size: 1.5rem;
    font-style: italic;
    text-align: center;
}