.container {
  width: 100%;
  height: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  padding: 1em;
  opacity: 0;
  transform: translateY(-1000px);
}

.show {
  animation: show 5s forwards;
}

@keyframes show {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  15%, 90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100px);
  }
}

.wrapper {
  width: 100%;
  max-width: 48em;
  padding: 2em;
  padding-top: 4em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 204, 156, 0.8);
}

.text {
  font-size: 2.5rem;
  margin-bottom: 1em;
}

.logo {
  display: block;
  margin-bottom: 2em;
}

.button {
  padding: 0.75em 4em;
  border-radius: 0.25em;
  color: white;
  background: var(--card-bg);
  cursor: pointer;
}