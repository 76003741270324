.container {
    width: 100%;
    max-width: calc(1300px - 2em);
    margin: 0 auto;
    padding: 0.5em 1em;
    border-radius: 0.5em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2em;
    font-size: 1.1rem;
    border: 1px solid #C4C4C4;
    margin-bottom: 4em;
}

.container * {
    transition: all 0.05s;
}

.wrapper {
    width: 63%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em 2em;
}

.header {
    width: 100%;
    font-weight: bold;
    font-size: 1.6rem;
    border-bottom: 1px solid #C4C4C4;
}

.content .header {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 1.6rem;
    border-bottom: none;
}

.subHeading {
    width: 100%;
    font-weight: bold;
    font-size: 1.2rem;
}

.innerWrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2em;
    perspective: 1000px;
}

.content {
    width: 100%;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
}

.web1, .web2, .web3 {
    background: #27292C;
    border-radius: 1em;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: default;
    transition: all 650ms;
}

.web1, .web2 {
    flex: 50%;
}

.web1:hover,
.web2:hover {
    transform: rotateY(10deg);
}

.web2:hover .arrow,
.web1:hover .arrow{
    animation: point 0.65s infinite alternate;
}

@keyframes point {
    from{ transform: translateX(110%);}
    to{transform: translateX(90%);}
}

.web3Wrapper {
    flex: 1;
    padding: 1em;
    border-radius: 1em;
    background: #114437;
    border: 3px solid transparent;
    transition: all 650ms;
}

.web3Wrapper:hover {
    border-color: #156853;
}

.web3Wrapper .image {
    max-width: 60%;
    margin: 0 auto;
    margin-bottom: 1em;
}

.image {
    display: block;
    width: 100%;
}

.web3 .img {
    display: block;
    width: 100%;
}

.tag {
    width: 12em;
    text-align: right;
    padding: 0.5em;
    border-radius: 0.5em;
    position: absolute;
    background: linear-gradient(to right, #343535 10%, #151616 90%);
    right: 1em;
    top: 1em;
}

.arrow {
    position: absolute;
    right: 0;
    transform: translateX(100%);
    z-index: 50;
}

@media screen and (max-width: 1300px) {
    .container {
        max-width: calc(1100px - 2rem);
    }
}

@media screen and (max-width: 1100px) {
    .container {
        max-width: calc(900px - 2rem);
        flex-direction: column;
    }

    .wrapper {
        width: 100%;
    }

    .web3 {
        width: 100%;
        border-radius: 2em;
    }

    .web2 {
        justify-content: flex-end;
    }

    .web2 .arrow {
        right: unset;
        transform: translateX(0) translateY(100%) rotate(90deg);
    }

    .web2:hover {
        transform: rotateY(0deg) rotateX(-10deg);
    }

    .web2:hover .arrow{
        animation: pointDown 0.65s infinite alternate;
    }

    @keyframes pointDown {
        from{ transform: translateY(110%) rotate(90deg);}
        to{transform: translateY(90%) rotate(90deg);}
    }
}

@media screen and (max-width: 900px) {
    .container {
        max-width: calc(700px - 2rem);
    }
}

@media screen and (max-width: 700px) {
    .container {
        max-width: calc(500px - 2rem);
    }

    .innerWrapper {
        flex-direction: column;
    }

    .web1, .web2 {
        width: 100%;
    }

    .web1 {
        justify-content: flex-end;
    }

    .web1 .arrow {
        right: unset;
        transform: translateX(0) translateY(100%) rotate(90deg);
    }

    .web1:hover {
        transform: rotateY(0deg) rotateX(-10deg);
    }

    .web1:hover .arrow{
        animation: pointDown 0.65s infinite alternate;
    }

    @keyframes pointDown {
        from{ transform: translateY(110%) rotate(90deg);}
        to{transform: translateY(90%) rotate(90deg);}
    }
}

@media screen and (max-width: 500px) {
    .container {
        max-width: calc(95%);
    }

    .web3Wrapper .image {
        max-width: 80%;
    }

    .web3 {
        border-radius: 1em;
    }

    .tag {
        width: 90%;
        font-size: 0.85rem;
    }
}


