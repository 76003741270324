.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.songWrapper {
  width: 100%;
}

.song {
  width: 100%;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  color: white;
  border: 2px solid #00CC9B;
  cursor: pointer;
  transition: all 0.3s;
}

.song:hover {
  border: 2px solid #008364;
}

.song.show {
  background: #00CC9B;
  color: black;
  margin-bottom: 0.5em;
}

.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  font-size: 1.2rem;
}

.right {
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .left, .right {
    font-size: 1rem;
    white-space: nowrap;
  }

  .description {
    display: none;
  }
}