.sort {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  color: #00CC9B;
}

.activeSort {
  cursor: pointer;
  padding: 0.25em 0.5em;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sortDropdown {
  position: absolute;
  transform: translateY(1.75em);
  background: #272727;
  box-shadow: 0px 17.6243px 17.6243px rgba(0, 0, 0, 0.25);
  border-radius: 0.25em;
  padding: 1em 0.5em;
  transition: all 350ms;
  visibility: hidden;
  opacity: 0;
  font-size: 1.2rem;
  min-width: 14em;
  gap: 1em;
}

.sortDropdown.dropdown {
  transform: translateY(2em);
  visibility: visible;
  opacity: 1;
}

.sortDropdown li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  cursor: pointer;
  padding: 0.5em;
}

.sortDropdown li:hover {
  background: #242424;
}

.dropIcon {
  margin-left: 2em;
  width: 0.6em;
  height: 0.6em;
}

.active {
  margin-left: 2em;
  width: 1em;
  height: 1em;
}
