.container {
  width: 100vw;
  height: 100vh;
  background: var(--main-bg);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 1em;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 4.5rem;
  color: white;
  opacity: 0;
}

.text.left {
  animation: slideRight 1s forwards;
  animation-delay: 1s;
}

.text.right {
  animation: slideLeft 1s forwards;
  animation-delay: 1s;
}

.logo {
  width: 5em;
  display: inline-flex;
  animation-name: rotate;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
  transform: rotate(15deg);
}

@keyframes rotate {
  0% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 768px) {
  .text {
    width: 2em;
  }

  .logo {
    width: 3em;
  }
}

@media screen and (max-width: 480px) {
  .text {
    display: block;
    width: 1.2em;
  }

  .logo {
    width: 2em;
  }
}