.container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: 0.5em;
  filter: drop-shadow(0px 4.76149px 4.76149px rgba(0, 0, 0, 0.25));
  background: transparent;
  color: white;
}

.image {
  height: 9em;
  width: 9em;
  border: 2px solid #00CC9B;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  overflow: hidden;
  gap: 0.25em;
}

.innerWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
}

.title {
  font-size: 1.2rem;
  color: white;
}

.artist {
  display: flex;
  align-items: center;
  font-style: italic;
  margin-bottom: 0.5em;
}

.artist img {
  width: 1em;
  height: 1em;
}

.verified {
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.5em;
}

.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
}

.releaseDate,
.duration {
  font-size: 0.85rem;
  font-weight: lighter;
  text-transform: capitalize;
}

.views {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4em;
  font-size: 1.2rem;
  font-weight: bold;
}

.views img {
  width: 1em;
  height: 1em;
}
