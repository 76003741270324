.play {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}

.base {
  border: 2px solid #018D6C;
  width: 20em;
  height: 20em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.base_, .player{
  width: 100%;
  height: 100%;
  position: absolute;
}

.base_ {
  transform: translate(-1em, -1em);
  border: 2px solid #018D6C;
}

.player {
  transform: translate(1em, 1em);
  background: white;
  z-index: 10;
}

.arrow {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow .right,
.arrow .left {
  border: 1px solid #018D6C;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75em 1em;
}

.arrow .right img,
.arrow .left img{
  width: 1em;
  height: 1em;
}

.arrow .left {
  transform: translateX(-100%);
}

.arrow .right {
  transform: translateX(100%);
}

.control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}

.control img {
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;
}

.level {
  width: 28em;
  margin-bottom: 2em;
  border-radius: 0.5em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.levelInner {
  width: 100%;
  border-radius: 0.4em;
}

.time {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(0, -1.5em);
  font-style: italic;
  font-size: 0.85rem;
  color: white;
}

.artist {
  text-align: center;
  font-size: 1.2rem;
}

.artist h1 {
  color: white;
}

.name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.name p {
  font-style: italic;
  font-size: 2rem;
}

.verified {
  margin-left: 0.5em;
  width: 1.2em;
  height: 1.2em;
}
