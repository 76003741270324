.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
  padding-right: 5em;
  position: relative;
  cursor: pointer;
}

.name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
}

.amount  {  
  font-size: 1.75rem;
  font-weight: bold;
}

.verified {
  margin-left: 0.5em;
  width: 1em;
  height: 1em;
}

.innerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
}

.coinWrapper {
  perspective: 1000px;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  padding: 0.1em;
  background: black;
}

.coin {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate 10s linear infinite forwards;
  overflow: hidden;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) rotateY(0deg) rotateX(0deg);
  }
  100% {
    transform: rotate(360deg) rotateY(360deg) rotateX(360deg);
  }
}

.dropdownWrapper {
  padding-left: 2em;
  width: 100%;
  position: relative;
  height: 0;
  overflow: hidden;
}

.wrapper.active .dropdownWrapper{
  height: 100%;
}

.wrapper.active .container{
  background: linear-gradient(90deg, #00CC9B 28.15%, rgba(0, 204, 155, 0.5) 100%);
  color: black;
  border: 1px solid transparent;
}

.wrapper.inactive .container{
  background: transparent;
  color: white;
  border: 1px solid #00CC9B;
}