
.container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16em;
}

.container * {
  color: white;
}

.heading {
  width: 100%;
  margin-bottom: 4em;
}

.heading h1 {
  font-size: 7em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  line-height: 85.5%;
  margin-bottom: 0.1em;
}

.heading h1 .accent {
  margin: 0;
  padding: 0;
  display: inline-block;
  color: transparent;
  background: linear-gradient(180deg, #00CC9B 30%, #00523e1a 90%);   
  -webkit-background-clip: text;
  background-clip: text;
}

.heading p {
  font-size: 1.5rem;
  text-align: center;
}

.previewWrapper,
.formWrapper {
  width: 100%;
}

.formHeading {
  width: 100%;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 0.75em;
}

.formHeading .accent {
  color: #00CC9B;
}

.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1em;
}

.title h3 {
  width: 100%;
  font-size: 2rem;
  text-transform: capitalize;
}

.title .underline {
  width: 100%;
  height: 4px;
  background: linear-gradient(101.64deg, #00CC9B 31.08%, #0098CC 66.07%);
  border-radius: 257.778px;
}

.description {
  width: 100%;
  text-align: left;
  margin-bottom: 2em;
}

.formContainer {
  width: 100%;
  margin-bottom: 2em;
  background: #1E1E1E;
  opacity: 0.9;
  border-radius: 9.40797px;
  padding: 2em;
}

.formContainer h2 {
  width: 100%;
  text-align: center;
  font-size: 1.75rem;
  color: transparent;
  background: linear-gradient(101.64deg, #00CC9B 31.08%, #0098CC 66.07%);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: bold;
}

.previewHeading{
  width: 100%;
  font-size: 2.5rem;
  text-align: center;
}

.previewWrapper .description {
  text-align: center;
  font-weight: bold;
  margin-bottom: 2em;
}

@media screen and (max-width: 1000px) {
  .container {
    max-width: 48em;
  }
}

@media screen and (max-width: 868px) {
  .container {
    max-width: 36em;
  }
}

@media screen and (max-width: 640px) {
  .container {
    padding: 0 0.5em;
  }
  .heading {
    margin-bottom: 2em;
  }
  .heading h1 {
    font-size: calc(2rem + 4vmax);
  }
  .heading p {
    font-size: calc(1rem);
  }
  .title h3 {
    font-size: calc(1rem + 1vmax);
  }
  .formContainer h2 {
    font-size: calc(1rem + 0.5vmax);
  }
  .formHeading,
  .previewHeading {
    font-size: calc(1rem + 1vmax);
  }
}