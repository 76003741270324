.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.searchIcon {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  margin-left: 0.5em;
}

.searchContainer {
  width: 16em;
  height: 2em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchContainer input {
  width: 100%;
  height: 100%;
  padding: 0.5em;
  transform: translateX(100%);
  transition: all 350ms;
  border: 1px solid #00CC9B;
  outline: none;
  font-size: 1.1rem;
  background: white;
  color: black;
}

.searchContainer input::placeholder {
  font-size: 1rem;
}

.searchContainer input.searchbar {
  transform: translateX(0%);
}

